import "./App.css";
import { useEffect, useState } from "react";
import popup from "./popup.png";
import { ReactComponent as Copy } from "./copy.svg";
import PrivacyPolicy from "./PrivacyPolicy";
import { Route, Routes, Link } from "react-router-dom";

let openedWindow;
var interval;
var count = 0;

function App1() {
  const [loaded, setLoaded] = useState(true);
  const [paused, setPaused] = useState(false);
  const [list, setList] = useState([]);

  const [firstTime, setFirstTime] = useState(true);
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)

      .catch((err) => console.error("Failed to copy text: ", err));
  };

  useEffect(() => {
    if (paused) {
      if (firstTime) {
        openScreenshot();
        setFirstTime(false);
      }
      interval = setInterval(() => {
        openScreenshot();
      }, 8000);
    } else {
      setFirstTime(true);
      clearInterval(interval);
    }
    //
  }, [paused]);

  function openScreenshot() {
    var randomURL = (Math.random() + 1).toString(36).substring(6);
    openedWindow = window.open(`https://prnt.sc/${randomURL}`);
    setTimeout(() => {
      openedWindow.close();
    }, 5000);
    setList((list) => ["https://prnt.sc/" + randomURL, ...list], count++);
  }

  return (
    <>
      {loaded ? (
        <div className="App">
          <head id="head-ad"></head>
          <div className="App-header">
            <div id="container">
              <div id="container-title">
                <p>Welcome to </p>{" "}
                <p id="container-title-h1"> The Unseen Screenshots!</p>
              </div>
              <div id="container-description">
                <p>
                  This website displays random screenshots from print.sc
                  website.{" "}
                  <span style={{ color: "rgba(255, 255, 0, 0.642)" }}>
                    To start press button "Start"
                  </span>
                  , wich will then start displaying random screenshots in 8
                  seconds interval. If you whana stop just press button "Stop".
                  If you are new to this website, check if you have{" "}
                  <span style={{ color: "rgba(255, 255, 0, 0.642)" }}>
                    allowed pop-up windows
                  </span>{" "}
                  <img src={popup}></img> for this site because, the random
                  images are opened in new tabs and the automaticly closed after
                  5 seconds. Below is a{" "}
                  <span style={{ color: "rgba(255, 255, 0, 0.642)" }}>
                    list of all opened links
                  </span>
                  , wich can be opend afterwards. First link in the list is
                  allways latest opened screenshot.
                </p>
                <p id="container-description-warning">
                  WARNING: Use of this website is at your own risk! We are not
                  responsible for any misuse, including but not limited to data
                  abuse, harmful intentions, violence, theft, or any illegal
                  activities!
                </p>
              </div>

              <div className="App-button" onClick={() => setPaused(!paused)}>
                {paused ? <h3>Stop</h3> : <h3>Start</h3>}
              </div>

              <div id="link-container">
                <h3>List of opened links</h3>
                <p className="link-hover" id="last-opend">
                  Last opend screenshot:{" "}
                  <a href={list[0]} target="_blank" className="App-link">
                    {list[0]}
                  </a>{" "}
                </p>
                <p id="older-links">Older:</p>
                {list.map((link, i) => {
                  return (
                    <p className="link-hover" key={link}>
                      {i} Link of the screenshot:
                      <a href={link} target="_blank" className="App-link">
                        {" " + link}
                      </a>
                      <div>
                        <div onClick={handleCopy(link[i])}>
                          <Copy id="copy-icon" />
                        </div>
                      </div>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <footer className="blockquote-footer">
        <Link to="/privacy-policy" className="text-white">
          Privacy Policy
        </Link>
      </footer>
    </>
  );
}

export default App1;
