import "./App.css";
import React from "react";
import { useState } from "react";

export default function PrivacyPolicy() {
  const [showModal, setShowModal] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleShowEmail = () => {
    setShowEmail(true);
  };

  return (
    <>
      <div className="App">
        <head id="head-ad"></head>
        <div className="App-header">
          <div id="container">
            <div id="container-title">
              <h1 id="container-title-h1">Privacy Policy</h1>

              <span id="ezoic-privacy-policy-embed"></span>

              <div>
                <p>
                  Contact Us If you have questions or concerns about this
                  Privacy Policy, please contact us at:
                </p>
                {!showEmail ? (
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={handleShowEmail}
                  >
                    Show Email
                  </button>
                ) : (
                  <p>Email: gospod.opeka@gmail.com</p> // Replace with your actual email
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
