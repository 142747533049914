import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App1 from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App1 />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  </BrowserRouter>
);
